// Vuetify Documentation https://vuetifyjs.com

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import {
  VCol,
  VRow
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VCol,
    VRow
  }
})

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#34675c',
        secondary: '#324851',
        accent: '#7da3a1'
      },
      dark: {}
    }
  }
})
