// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'HOME',
          component: () => import('@/views/home/Index.vue')
        },
        {
          path: 'company',
          name: 'COMPANY',
          component: () => import('@/views/company/Index.vue'),
          meta: { src: require('@/assets/company.jpg') }
        },
        {
          path: 'service',
          name: 'SERVICE',
          component: () => import('@/views/service/Index.vue'),
          meta: { src: require('@/assets/service.jpg') }
        },
        {
          path: 'contact-us',
          name: 'CONTACT',
          component: () => import('@/views/contact-us/Index.vue'),
          meta: { src: require('@/assets/contact.jpg') }
        },
        {
          path: 'recruit',
          name: 'RECRUIT',
          component: () => import('@/views/recruit/Index.vue'),
          meta: { src: require('@/assets/recruit.jpg') }
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue')
        }
      ]
    }

  ]
})

export default router
