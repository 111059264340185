<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-title
        size="body-1"
        space="4"
        title="MULTI-PURPOSE THEME"
        weight="regular"
      />

      <base-body>
        XXXX compornent/base/info.vueにあります。
      </base-body>

    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean
    },

    data: () => ({
      business: [
      ]
    })
  }
</script>
