<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        :justify="justify"
        no-gutters
        class="mb-5"
        align="center"
      >
        <v-col
          cols="0" sm="0" md="1" />
        <v-col
          cols="12" sm="3" md="2"
          >
          <base-title
            :title="date"
            class="text-uppercase"
            space="0"
          />
          <base-divider :color="color" />
        </v-col>
        <v-col
          cols="12" sm="9" md="8">
          <base-body
            v-if="text || $slots.default"
            :text="text"
            space="6">
            <slot />
          </base-body>
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseNewsRecord',

    mixins: [Heading],

    props: {
      dark: Boolean,
      color: {
        type: String,
        default: 'primary'
      },
      date: String,
      text: String
    }
  }
</script>
