import WebFontLoader from 'webfontloader'

// async load fonts
WebFontLoader.load({
  google: {
    families: [
      'PT+Sans:400,700&display=swap',
      'Work+Sans:100,300,400,500,700,900&display=swap',
      'Noto+Sans+JP:400,500,700,900&display=swap',
      'M+PLUS+1p:100,300,400,500,700,900&display=swap'
    ]
  }
})
